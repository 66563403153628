import React from "react"

import Seo from "../components/Seo"
import Layout from "../components/Layout"

import { graphql, Link } from "gatsby"

import Grid from "../components/Grid"
import SafeAreaWrapper from "../components/SafeAreaWrapper"
import Hero from "../components/Hero"
import { Button } from "../components/Button"

import RecentNewFeatures from "../components/RecentNewFeatures"

import { MonitorPlay, PersonWithHeadset } from "../components/Icon"

const SupportPage = ({ data: { page, backgroundImage } }) => {
	return (
		<Layout translucent={true}>
			<Seo
				title={page.seo.title}
				description={page.seo.description}
				keywords={page.seo.keywords}
			/>
			<Hero
				title="Support"
				subtitle="Help and advice just for you"
				backgroundImage={backgroundImage}
				translucent={true}
			/>
			<div className="py-spacer-xl">
				<SafeAreaWrapper>
					<Grid className="gap-y-40">
						<div className="col-span-12 lg:col-span-5 lg:col-start-2">
							<div className="bg-porcelain px-30 pt-80 pb-60 flex flex-col items-center rounded-medium h-full">
								<PersonWithHeadset className="w-[75px] h-[75px] text-pictonblue" />
								<h2 className="display2 text-center my-30">
									Customer support via our helpdesk
								</h2>
								<p className="text-center mb-30 grow">
									Do you need our support for the solution of your problem? It
									would be our pleasure to help you. Get in touch with our
									customer support team via our helpdesk portal for assistance
									with Cloud Fleet Manager.
								</p>
								<a
									href="https://hanseaticsoft.freshdesk.com/support/tickets/new"
									target="_blank"
									rel="noreferrer">
									<Button primary={true} label="Get Support" />
								</a>
							</div>
						</div>
						<div className="col-span-12 lg:col-span-5">
							<div className="bg-porcelain px-30 pt-80 pb-60 flex flex-col items-center rounded-medium h-full">
								<MonitorPlay className="w-[75px] h-[75px] text-pictonblue" />
								<h2 className="display2 text-center my-30">
									Remote session with TeamViewer
								</h2>
								<p className="text-center mb-30">
									In some rare cases, we will ask you to join a remote session via
									TeamViewer. This enables us to see precisely what you are
									talking about and to better assist you. For that reason, you
									need a copy of our TeamViewer client, which you can download
									below.
								</p>
								<a
									href="https://get.teamviewer.com/hanseaticsoft"
									target="_blank"
									rel="noreferrer">
									<Button primary={true} label="Download TeamViewer" />
								</a>
							</div>
						</div>
					</Grid>

					<Grid className="mt-spacer-xl gap-y-40">
						<div className="col-span-12 lg:col-span-6">
							<Link to="/faq">
								<div className="bg-pictonblue hover:shadow-xl duration-300 transition-color pt-60 pb-80 px-60 flex flex-col items-start rounded-medium h-full">
									<span className="captionSmall text-left text-white uppercase mb-10">
										Frequently asked questions
									</span>
									<h2 className="display2 text-left text-white">
										What can we do for your company
									</h2>
								</div>
							</Link>
						</div>
						<div className="col-span-12 lg:col-span-6">
							<Link to="/contact">
								<div className="bg-tealblue hover:shadow-xl duration-300 transition-color pt-60 pb-80 px-60 flex flex-col items-start rounded-medium h-full">
									<span className="captionSmall text-left text-white uppercase mb-10">
										Say hello to us
									</span>
									<h2 className="display2 text-left text-white">
										Learn about our locations and contact our different offices
									</h2>
								</div>
							</Link>
						</div>
					</Grid>

					<RecentNewFeatures />
				</SafeAreaWrapper>
			</div>
		</Layout>
	)
}

export const pageQuery = graphql`
	query {
		page: strapiSupportPage {
			seo {
				title
				description
				keywords
			}
		}
		backgroundImage: file(relativePath: { eq: "customer-support-operator.jpg" }) {
			childImageSharp {
				gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR)
			}
		}
	}
`

export default SupportPage
